
















































































































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import AssignCard from "@/components/common/AssignCard.vue";
import MainSlogan from "@/components/common/MainSlogan.vue";
import WordOnPic from "@/components/common/WordOnPic.vue";
import PicWall from "@/components/common/PicWall.vue";
@Component({
  components: {
    Layout,
    AssignCard,
    MainSlogan,
    WordOnPic,
    PicWall,
  },
})
export default class ZCBuilding extends Vue {
  data() {
    return {
      hbg: "",
      sthree: require("@/assets/storage/img/sthree.png"),
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  }
  goZCSolar() {
    this.$router.push({
      path: "/zeroCarbon/zcbuilding/solar",
    });
  }
  goChargepile() {
    this.$router.push({
      path: "/zeroCarbon/zcbuilding/storage",
    });
  }
  goCharge() {
    this.$router.push({
      path: "/zeroCarbon/zcbuilding/charge",
    });
  }
  onSubmit() {
    console.log("submit!");
  }
}
